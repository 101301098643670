export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAnpmhaXDqtIGf5LZ9xnVqN2nQM2vgAaPM',
    authDomain: 'lawnvalue-production-37b2c.firebaseapp.com',
    databaseURL: 'https://lawnvalue-production-37b2c.firebaseio.com',
    projectId: 'lawnvalue-production',
    storageBucket: 'lawnvalue-production.appspot.com',
    messagingSenderId: '571713791733',
    appId: '1:571713791733:web:7e9e4a948fe49d812147bc',
    measurementId: 'G-Q6SJLRKQ4W',
  },
  authorizenet: {
    loginId: '3TtX29hzJ',
    transactionKey: '5n5tCqM2Ur97r8Ga',
    endpointSandbox: 'https://apitest.authorize.net/xml/v1/request.api',
    endpointProduction: 'https://api.authorize.net/xml/v1/request.api',
  },
  stackdriver: {
    key: 'AIzaSyAnpmhaXDqtIGf5LZ9xnVqN2nQM2vgAaPM',
    projectId: 'lawnvalue-production',
  },
  smartystreet: {
    key: '12983890447099416',
    endpoint: 'https://us-autocomplete-pro.api.smartystreets.com/lookup',
  },
  typesense: {
    apiKey: 'kFnQj36H5xZmzUMZwHHnX8wkbYBh76XD', // search only API Key
    adminApiKey: 'yVxJFqREnUvdoFolhLHaajz4JJz4izql',
    nodes: [
      {
        host: 'rucvye76n04ski9jp-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
  },
  functionsOrigin: false,
  firestoreSettings: false,
};

// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: 'AIzaSyCGDt23lpSqihuetW6j5Y06QkQfVktLVjY',
//     authDomain: 'lawnvalue-staging-7931a.firebaseapp.com',
//     databaseURL: 'https://lawnvalue-staging-7931a.firebaseio.com',
//     projectId: 'lawnvalue-staging-7931a',
//     storageBucket: 'lawnvalue-staging-7931a.appspot.com',
//     messagingSenderId: '1025627019451',
//     appId: '1:1025627019451:web:91d30f97a5aacc7058b19e',
//     measurementId: 'G-VPHXCE4N40',
//   },
//   authorizenet: {
//     loginId: '5sN2u4Wpy',
//     transactionKey: '88rYQ82s62q4LL5x',
//     endpointProduction: 'https://apitest.authorize.net/xml/v1/request.api',
//   },
//   smartystreet: {
//     key: '12983890447099416',
//     endpoint: 'https://us-autocomplete-pro.api.smartystreets.com/lookup',
//   },
//   stackdriver: {
//     key: '',
//     projectId: '',
//   },
//   typesense: {
//     apiKey: 'U1YOcwSz2Q02cab2O1hdrTzksmkbNf0F', // search only API Key
//     adminApiKey: 'EcATrVbSJLgnOyxfMq2DJf3YVgw5oln9', // all access API Key
//     nodes: [
//       {
//         host: '54tcfjao2dkw19lsp-1.a1.typesense.net',
//         port: 443,
//         protocol: 'https',
//       },
//     ],
//   },
//   functionsOrigin: false,
//   firestoreSettings: false,
//   // functionsOrigin: 'http://localhost:5001',
//   //  firestoreSettings: { host: 'localhost:8080', ssl: false },
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
